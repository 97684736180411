<template>
  <div class="col-md-9">
    <div
      class="card"
      style="border: none"
      id="print"
    >
      <div class="card-body">

        <div class="text-center">
          <ImageComponent place="logo-invoice"></ImageComponent>
          <h4 style="font-weight: bold; margin-top: 20px; text-transform: uppercase;">
            Laporan Stok Opname
            <hr style="border-bottom: 2px solid green; margin-right: 200px; margin-left: 200px; margin-top: 5px; margin-bottom: 5px;" />
          </h4>
        </div>

        <div class="row mb-2 mt-4">
          <div class="col-3">
            <span class="d-block">Tanggal :</span>
            <span class="font-weight-bolder">{{data.checking_date}}</span>
          </div>
          <div class="col-3">
            <span class="d-block">Pegawai :</span>
            <span class="font-weight-bolder">{{data.employee_name}}</span>
          </div>
          <div class="col-3">
            <span class="d-block">Selisih(+) :</span>
            <span class="font-weight-bolder">{{parseInt(data.quantityPlus == null ? 0 : data.quantityPlus ).toLocaleString('id-ID')}}</span>
          </div>
          <div class="col-3">
            <span class="d-block">Selisih(-) :</span>
            <span class="font-weight-bolder">{{parseInt(data.quantityMinus == null ? 0 : data.quantityMinus ).toLocaleString('id-ID')}}</span>
          </div>
          <!-- <div class="col-3">
            <span class="d-block">Kerugian(Rp) :</span>
            <span class="font-weight-bolder">{{parseInt(data.amount == null ? 0 : data.amount ).toLocaleString('id-ID')}}</span>
          </div> -->
        </div>

        <div class="row mb-2">
            <div class="col-12">
                <b-table bordered :items="items" :fields="fields">
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.key === 'actions' ? '20%' : '' }"
                    />
                  </template>
                  <template #cell(warehouse)="data">
                    <div v-for="(a,index) in data.item.warehouse" :key="index">
                      <span v-if="data.item.warehouse[index] !== ''" >{{data.item.warehouse[index] +"-"+ data.item.rack[index]}}</span>

                    </div>
                  </template>
                  <template #cell(checking_stock)="data">
                    <span v-if="data.item.checking_stock > 0" >{{data.item.checking_stock}}</span>
                  </template>
                  <template #cell(difference_stock)="data">
                    <span v-if="data.item.checking_stock > 0" >{{data.item.difference_stock}}</span>
                  </template>
                  <template #cell(sales_price)="data">
                    <span>{{ parseInt(data.item.sales_price).toLocaleString('id-ID') }}</span>
                  </template>
                  <template #cell(lost_sales)="data">
                    <span>{{ parseInt(data.item.lost_sales).toLocaleString('id-ID') }}</span>
                  </template>
                </b-table>
            </div>
        </div>
        

    </div>
  </div>
  </div>
</template>

<script>
import module from '@/core/modules/CrudModule.js'
import ImageComponent from "@/component/general/ImageComponent.vue";
export default {

  // props: {
  //   data: Object,
  //   paymentRemuneration:Array,
  //   pieceRemuneration:Array,
  //   taxRemuneration:Array,
  //   tableDisplay:Object,
  //   employee:Object,
  //   subTotalPayment:Number,
  //   subTotalPiece:Number,
  //   subTotalTax:Number,
  //   address:Object,

  // },

  components: {
    ImageComponent
  },

  data() {
    return {
      // Data
      data: [],
      // Other
      imgLoaded: false,
      // User Access
      btn: false,

      fields: [
        {
          key: "number",
          label: "#",
          
        },
        {
          key: "medicine_name",
          label: "Nama Obat & Alkes",
          
        },
        {
          key: "current_stock",
          label: "Stok Di Sistem",
          
        },
        {
          key: "checking_stock",
          label: "Stok Fisik",
          
        },
        {
          key: "difference_stock",
          label: "Selisih Stok",
          
        },
        {
          key: "sales_price",
          label: "Harga Jual(Rp)",
          
        },
        {
          key: "lost_sales",
          label: "Kerugian(Rp)",
          
        },
      ],
      items: [],
      // Pagination
      perPage: 2000,
      currentPage: 1,
      totalRows: 0,
    }
  },

  methods: {

    async pagination() {
      
      let response = await module.paginate(
        `detail-stock-opnames/by-stock/report/${this.$route.params.id}`,
        ``
      );
     
      this.items = response.data;
    },

    async get() {
      this.data = await module.get('stock-opnames/' + this.$route.params.id)
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/stock-opnames')
      }
      this.pagination()
    },
  },

  mounted() {
    this.get()
    this.pagination()
    
  },



  


}
</script>

<style>
</style>